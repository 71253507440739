import { useEffect, useState } from "react";
import * as Sentry from "@sentry/node";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import axios from "axios";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
// import CookiesComponent from "../components/Cookies/Cookies";
import { UserProvider } from "../context/UserContext";
import * as gtag from "../services/gtm";

import "../styles/globals.css";
import "../styles/wysiwyg.css";
import "../styles/ads.css";
import "../styles/contact.css";
import "../styles/alice-carousel.css";
import "../styles/cookies.css";
import theme from "../theme/theme";
import { AdoSubscriber } from "../components/Ado/AdoSubscriber";
import { AlertModalController } from "../hooks/useAlert";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    ignoreErrors: [
      'TypeError: t[0] is null',
      'h.ScrollTracker is not a function',
      'Cannot read properties of null',
      'TypeError: n is null',
    ],
  });
}

NProgress.configure({ showSpinner: false });

function MyApp({ Component, pageProps, err, ...rest }) {
  const router = useRouter();
  const [showCookies, setShowCookies] = useState(false);
  const [metatagsFromApi, setMetatagsFromApi] = useState([]);
  /**
   * user data - data passed to context; available in all app
   * @param {string} id - user id
   * @param {string} token - user token - temporaty data, only after user logged;
   * that value is saved to cookies httpOnly. It can be used on server side requests (getServerSideProps)
   * @param {boolean} authorized - that value inform if user is authorized (if token exist in cookies).
   * This value is initialized on first load app.
   * If you want to check, if user is authorized, always get value form "authorized", not from "token"
   */
  const [user, setUser] = useState({
    id: null,
    token: null,
    authorized: false,
  });
  const productionMode = process.env.NODE_ENV === "production";

  useEffect(() => {
    const handleRouteChange = (url) => {
      NProgress.start();
      /**
       * first load page, this variable fix next.js "bug" - routeChangeComplete doesn't work at first load page.
       * It work's after change url
       */
      window.firstLoad = false;
    };
    const handleRouteComplete = (url) => {
      NProgress.done();
      if (productionMode) {
        gtag.pageview(url);
      }
    };
    router.events.on("routeChangeStart", handleRouteChange);
    router.events.on("routeChangeComplete", handleRouteComplete);
    router.events.on("routeChangeError", handleRouteComplete);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
      router.events.off("routeChangeComplete", handleRouteComplete);
      router.events.off("routeChangeError", handleRouteComplete);
    };
  }, []);

  useEffect(() => {
    /**
     * on init app - get information about user (if is authorized)
     */
    axios
      .get("/api/auth", {
        headers: {
          Authorization: `Token ${process.env.token}`,
        },
      })
      .then((resp) => {
        if (resp.status === 200 && resp.data.isAuthorized) {
          setUser({ ...user, authorized: true });
        }
      });
  }, []);

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    const cookieReminder = Cookies.get("cookie_reminder", {
      domain: "localhost",
    });
    cookieReminder !== "true" && setShowCookies(true);
  }, []);
  return (
    /**
    user context
   */
    <UserProvider user={[user, setUser]}>
      <ThemeProvider theme={theme}>
        <AlertModalController>
          <CssBaseline />
          {/* {showCookies && <CookiesComponent />} */}
          <AdoSubscriber>
            <Component {...pageProps} err={err} />
        </AdoSubscriber>
        </AlertModalController>
      </ThemeProvider>
    </UserProvider>
  );
}

export default MyApp;
