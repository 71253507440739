import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext,
  memo,
} from "react";
import { Dialog } from "../components/Dialog/Dialog";
import styled from "styled-components";
import Button from "../components/Button/Button";

const S = {
  Btn: styled(Button)`
    margin: auto;
    display: block;
    margin-top: 20px;
  `,
  Body: styled(Dialog.Body)`
    text-align: center;
    h1 {
      color: #4762ab;
    }
    p {
      font-weight: 600;
    }
  `,
};

export const context = createContext({});

export const AlertModalController = memo(({ children }) => {
  const [state, setState] = useState({
    isOpen: false,
    options: null,
    text: "",
    title: "",
  });
  const close = useCallback(
    () => setState((s) => ({ ...s, isOpen: false })),
    []
  );
  const open = useCallback(
    (title, text, options) =>
      setState({ options: options ?? null, title, text, isOpen: true }),
    []
  );
  const value = useMemo(() => ({ ...state, close, open }), [
    state,
    open,
    close,
  ]);
  return (
    <context.Provider value={value}>
      {children}
      <Dialog isOpen={state.isOpen} onClose={close} size="medium">
        <>
          <S.Body>
            <h1>{state.title}</h1>
            <p>{state.text}</p>
            <S.Btn
              color="black"
              type="border"
              onClickFunction={() => {
                state.options?.callback?.();
                close();
              }}
            >
              {state.options?.btnText || "Zamknij"}
            </S.Btn>
          </S.Body>
        </>
      </Dialog>
    </context.Provider>
  );
});

/**
 * @example
 * const alert = useAlert();
 * alert.open("Some title!", "some text.");
 * alert.open(
      <div>
        <h5>Some title</h5>
        Some text.
        <Link to={...} onClick={alert.close}>
          Some additional link
        </Link>
        .
      </div>,
    );
    alert.open(({ close }) => (
      <div>
      ...
      <button onClick={close}>X</button>
      </div>
    ));
 */
export const useAlert = () => {
  const modal = useContext(context);
  return {
    open: (title, text, options) => {
      if (typeof text === "function") {
        modal.open(title, text(modal), options);
      } else {
        modal.open(title, text, options);
      }
    },
    close: modal.close,
  };
};
