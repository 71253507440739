import React from "react";
import styled from "styled-components";

const S = {
  Container: styled.div`
    position: absolute;
    opacity: 0;
    top: -100000px;
    left: -10000px;
    width: 100vw;
    height: 100vh;
    z-index: 20001;
    padding: 80px 20px 20px;
    background-color: rgba(0, 0, 0, 0.3);
    -moz-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in;
    overflow-x: auto;
    overflow-y: scroll;
    &.active {
      position: fixed;
      top: 0;
      left: 0;
      opacity: 1;
    }
  `,
  Content: styled.div`
    position: relative;
    background-color: #fff;
    max-width: ${({ size }) =>
      size === "medium" ? 800 : size === "small" ? 600 : 1000}px;
    margin: auto;
    border-radius: 15px;
  `,
  CloseBtn: styled.button`
    position: absolute;
    background: transparent;
    border: 1px solid transparent;
    top: -40px;
    right: 0;
    width: 35px;
    height: 35px;
    text-align: center;
    cursor: pointer;
    font-size: 18px;
    line-height: 35px;
    transition: 200ms;
    :hover {
      transform: scale(1.1);
    }
  `,
  Heading: styled.div`
    border-radius: 15px 15px 0 0;
    padding: 35px;
  `,
  Body: styled.div`
    padding: 35px;
  `,
};

/**
 *
 * @param {object} props
 * @param {"large" | "medium" | "small"} props.size
 */
export const Dialog = ({ children, isOpen, onClose, className, size }) => {
  return (
    <S.Container className={isOpen ? "active" : ""}>
      <S.Content className={className} size={size}>
        <S.CloseBtn type="button" onClick={onClose}>
          <img src="/images/xIcon.svg" alt="Zamknij" />
        </S.CloseBtn>
        {children}
      </S.Content>
    </S.Container>
  );
};
Dialog.Heading = S.Heading;
Dialog.Body = S.Body;
