import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#339D37",
    },
    secondary: {
      main: "#456DD6",
    },
  },
  typography: {
    fontFamily: ["Rajdhani", "sans-serif"].join(","),
  },
  overrides: {
    MuiInputLabel: {
      root: {
        fontSize: 16,
      },
    },
  },
  palette: {
    background: {
      default: "#fff",
    },
  },
});

export default theme;
